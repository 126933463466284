<!-- 电影订单支付-->
<template>
    <div class="root-wrap">
        <div class="body-wrap fx-fill">
            <div class="fill-box" ref="fillbox">
                <div class="order_content-wrap">
                    <div class="order_content fx-row">
                        <div>
                            <van-image class="order_poster" :src="orderInfo.logo" fit="fill"></van-image>
                        </div>
                        <div class="fx-fill fx-column fx-between">
                            <div class="order_content-title">
                                <div class="order_name">{{orderInfo.movieName}}</div>
                                <div style="">{{orderInfo.quantity || '-'}} 张</div>
                            </div>
                            <div>
                                <span>{{orderInfo.playTime}} </span>
                                <span v-if="orderInfo.edition">({{orderInfo.edition}})</span>
                            </div>
                            <div>{{orderInfo.seats}}</div>
                            <div>{{orderInfo.hallName}}</div>
                            <div>{{orderInfo.cinemaName}}</div>
                        </div>
                    </div>
                    <van-divider dashed :hairline="false" :style="{ color: $config.style.secondColor, margin: '10px 0'}"></van-divider>
                    <div class="order_tips fx-row fx-between">
                        <div class="fx-row fx-center">
                            <div>
                                <van-icon :name="require('@/assets/images/order/invalid.png')" size="10" style="margin-right: .4em;"></van-icon>
                                <span>不支持退票</span>
                            </div>
                            <div style="margin-left: 1.5em;">
                                <van-icon :name="require('@/assets/images/order/invalid.png')" size="10" style="margin-right: .4em;"></van-icon>
                                <span>不支持改签</span>
                            </div>
                        </div>
                        <div class="fx-row fx-center">
                            <van-icon :name="require('@/assets/images/order/time_out.png')" size="10" style="margin-right: .4em;"></van-icon>
                            <van-count-down ref="countDown" :time="orderInfo.remainTime" :auto-start="false" format="mm:ss" style="font-size: 1em;" @finish="payTimeout"></van-count-down>
                            <!-- <span :class="payRemainingTime < 300 ? 'fc-active' : ''">{{payRemainingTime | formatSecondToMin}}</span> -->
                        </div>
                    </div>
                    <div class="order-food-wrap" v-if="showFood">
                        <van-collapse v-model="food">
                            <van-collapse-item name="1" class="order-food" :border="false">
                                <template #title>
                                    <span class="fs-7">已选小食</span>
                                </template>
                                <template #value>
                                    <span class="fs-7">详情</span>
                                </template>
                                <div v-for="item, index in bmhList" :key="index" class="fs-7 order-food--value">
                                    <div>{{item.name}} x {{ item.qty }}</div>
                                    <div>￥{{item.price}}</div>
                                </div>
                            </van-collapse-item>
                        </van-collapse>
                    </div>
                </div>
                <!-- <div class="order_phone-wrap">
                    <van-cell @click="focusMobile = true" center>
                        <template #icon>
                            <van-image class="cell-icon" :src="require('@/assets/images/order/phone_ico.png')" fit="cover" width="36"></van-image>
                        </template>
                        <template #title>
                            <span v-if="focusMobile == false">{{receiveMobile || orderInfo.mobile}}</span>
                            <van-field v-else v-model="receiveMobile" ref="receiveMobile" placeholder="请输入手机号" @focus="onFocusMobile" @blur="onBlurMobile" :maxlength="11"></van-field>
                        </template>
                        <template #label v-if="focusMobile == false">购票成功后到“个人中心-我的订单”查看取票码</template>
                    </van-cell>
                </div> -->
                <div class="order_coupon-wrap">
                    <van-cell title="优惠券" center is-link value-class="widen" @click="toPickCoupon">
                        <template #icon>
                            <van-image class="cell-icon" :src="require('@/assets/images/order/coupon.png')" fit="cover" width="36"></van-image>
                        </template>
                        <div>
                            <span v-if="!orderInfo.discount && orderAvailableCoupon.length > 0" class="available-coupon">{{orderAvailableCoupon.length}}张可用</span>
                            <span v-if="orderInfo.discount" class="fc-active">-{{orderInfo.discount}}￥</span>
                        </div>
                    </van-cell>
                </div>
                <div class="order_payway-wrap">
                    <van-radio-group v-model="payWay">
                        <van-cell-group>
                            <van-cell title="微信" center @click="payWay = 'wx'">
                                <template #icon>
                                    <van-image class="cell-icon" :src="require('@/assets/images/order/wechat_pay.png')" fit="cover" width="36"></van-image>
                                </template>
                                <template #right-icon>
                                    <van-radio name="wx">
                                        <template #icon="props">
                                            <!-- 使用show 比if渲染更快 show:display:none-->
                                            <van-image v-show="props.checked" :src="require('@/assets/images/order/selected.png')" fit="cover" width="15"></van-image>
                                            <van-image v-show="!props.checked" :src="require('@/assets/images/order/select.png')" fit="cover" width="15"></van-image>
                                        </template>
                                    </van-radio>
                                </template>
                            </van-cell>
                            <van-cell title="余额" center @click="payWay = 'accountPay'">
                                <template #icon>
                                    <van-image class="cell-icon" :src="require('@/assets/images/order/balance_pay.png')" fit="cover" width="36"></van-image>
                                </template>
                                <template #label>
                                    <span style="font-size: 11px;text-decoration: underline;" class="fc-link" @click.stop="goBalance">切换余额卡</span>
                                </template>
                                <template>
                                    <div style="margin-right: 10px;">剩余￥{{ memberInfo.memberAccountBalance }}</div>
                                </template>
                                <template #right-icon>
                                    <van-radio name="accountPay">
                                        <template #icon="props">
                                            <van-image v-show="props.checked" :src="require('@/assets/images/order/selected.png')" fit="cover" width="15"></van-image>
                                            <van-image v-show="!props.checked" :src="require('@/assets/images/order/select.png')" fit="cover" width="15"></van-image>
                                        </template>
                                    </van-radio>
                                </template>
                            </van-cell>
                        </van-cell-group>
                    </van-radio-group>
                </div>
                <div class="card-wrap">
                    <van-cell-group>
                        <van-cell title="无座时接受系统换座" :border="false">
                            <template #right-icon>
                                <van-checkbox v-model="checkedSwitch" icon-size="16px" shape="square" checked-color="#19be6b"></van-checkbox>
                            </template>
                        </van-cell>
                        <van-cell :title="''" :label="changeSeatNote" center style="padding-top: 0;">
                        </van-cell>
                        <!-- <div>所选座次已被购买，系统将自动帮您选择相邻最近观影区 域的连号座次（特殊座次除外）</div> -->
                    </van-cell-group>
                </div>
                <div class="order_instructions-wrap">
                    <div class="fx-row fx-between">
                        <div class="fs-14">购票须知</div>
                        <van-checkbox v-model="readFlag" :class="readBlink ? 'text-blink' : ''" @change="changeRead">已读</van-checkbox>
                    </div>
                    <div v-html="orderInfo.orderNote" style="margin-top: 10px;">
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-wrap fx-row fx-between fx-center">
            <div class="order_amount fx-column">
                <div>
                    <span class="fc-active fw-7">总计</span>
                    <span class="fc-active fw-7"> ￥{{orderInfo.amount}}</span>
                </div>
                <div v-if="orderInfo.discount">
                    <span class="fc-tips" style="font-size: 10px;">已优惠</span>
                    <span class="fc-tips" style="font-size: 10px;"> ￥{{orderInfo.discount}}</span>
                </div>
            </div>
            <div><span class="order-detail_text" @click="showDetail = true">订单明细</span></div>
            <div class="order_confirm">
                <van-button type="primary" size="small" @click="beforePay" round style="padding: 2px 30px;">确认支付</van-button>
            </div>
        </div>
        <div class="footer-placeholder" :style="[footerHeight]"></div>
        <div class="utils-wrap">
            <van-popup v-model="showDetail" position="bottom" style="padding: 35px 20px 0;" :closeable="true" close-icon="close" round>
                <div class="detail-wrap fx-column">
                    <div class="detail-body fx-fill">
                        <div class="detail-title">订单明细</div>
                        <div class="detail-item">
                            <div>电影票</div>
                            <div>￥{{orderInfo.orderAmount}}</div>
                        </div>
                        <div v-if="showFood" class="detail-item">
                            <div>小食套餐</div>
                            <div>￥{{orderInfo.otherAmount}}</div>
                        </div>
                    </div>
                    <div class="detail-footer" :style="[footerHeight]"></div>
                </div>
            </van-popup>
            <van-dialog v-model="showTips" :show-confirm-button="false" style="background-color: transparent;" closeOnClickOverlay>
                <div class="tips-content">
                    <div class="tips-cinema fx-center">{{ orderInfo.cinemaName }}</div>
                    <div class="tips-movie fx-center">{{ orderInfo.movieName }}</div>
                    <div class="tips-playtime fx-center fc-error">
                        <img class="tips-symbol" :src="require('@/assets/images/order/bingo-green.png')" style="width: 25px;height: 25px;margin-right: 5px;" />
                        {{ $getCalendar(orderInfo.playTime, 2) }} 开场
                    </div>
                    <div class="tips-chupiaotime">{{ baseData.chupiaoTime }}</div>
                    <div class="tips-remark">请仔细核对订单，下单后有偿支持退改签，详情请阅读<span class="fc-error">购票须知</span></div>
                </div>
                <van-button type="primary" block style="border-radius: 0;" @click="showTips = false;beforePay(true)">我已知晓</van-button>
            </van-dialog>
            <!-- <van-popup v-model="showCoupon" round position="bottom" class="fx-column" style="height: 80%;padding-top: 4px;" @click-overlay="cancelSelectCoupon" :closeable="true" close-icon="close">
                <order-coupon-popup v-model="selectedCouponListTemp" :coupon-list="couponList" :un-used-coupon-list="unUsedCouponList" :exchange-card-list="exchangeCardList" :un-used-exchange-card-list="unUsedExchangeCardList" :max-selected="orderInfo.quantity" @confirm="doSelectCoupon" @refresh="getCouponInfo"></order-coupon-popup>
            </van-popup> -->
            <ali-pay-form :form-data="formData" :form-action="formAction"></ali-pay-form>
            <van-overlay :show="showPayStatus" :z-index="99" class-name="fx-center-middle">
                <div style="border-radius: 4px;background-color: #fff;width: 70%;">
                    <h3 class="fx-center" style="padding: 8px 0;">请确认支付是否已完成</h3>
                    <van-button block class="fc-active" @click="confirmPay">已完成支付</van-button>
                    <van-button block class='fc-tips' @click="showPayStatus = false">支付遇到问题，重新支付</van-button>
                </div>
            </van-overlay>
        </div>
    </div>
</template>

<script>
import { getOrder, doOrderPay } from '@api/order-request'
import { queryCouponByOrder } from '@api/coupon-request'
import { wxPaymentV2 } from '@/libs/wx-utils';
import { mapGetters } from 'vuex';
import AliPayForm from '@/components/order/ali-pay-form';
import OrderCouponPopup from '@/components/order/order-coupon-popup';
import { subtr } from '@/libs/number-utils'
import { filter } from 'lodash'

export default {
    data() {
        let radioIcons = [require('@/assets/images/order/select.png'), require('@/assets/images/order/selected.png')];
        return {
            radioIcons,
            orderId: this.$route.query.orderId,
            orderInfo: {},
            focusMobile: false,
            receiveMobile: '',
            payRemainingTime: 230,
            showCoupon: false,
            couponList: [],
            unUsedCouponList: [],
            exchangeCardList: [],
            unUsedExchangeCardList: [],
            selectedCouponListTemp: [], //临时选中优惠券集合
            selectedCouponList: [], //实际选中优惠券集合
            payWay: 'wx',
            useVip: false,
            formData: {},
            formAction: '',
            aliPayWebView: 'aliPay',
            showPayStatus: false,
            checkedSwitch: true,
            orderAvailableCoupon: [],
            food: ["1"],
            showDetail: false,
            showTips: false,
            footerHeight: '',
            readFlag: this.$route.query.read ? true : false,
            readBlink: false,
        }
    },
    computed: {
        ...mapGetters([
            'selectedSeatList',
            'loginPhone',
            'openId',
            'platform',
            'memberInfo',
            'baseData'
        ]),
        changeSeatNote() {
            let note = '所选座次已被购买，系统将自动帮您选择相邻最近观影区 域的连号座次（特殊座次除外）'
            if (this.orderInfo.acceptChgSeaNote) {
                return this.orderInfo.acceptChgSeaNote;
            }
            return note;
        },
        bmhList() {
            return this.orderInfo.bmhItemList || [];
        },
        showFood() {
            return this.bmhList && this.bmhList.length > 0 ? true : false;
        },
        selectedCouponTempNos() {
            let couponNoList = this.selectedCouponListTemp.map((item) => {
                return item;
            });
            return couponNoList.join(',');
        },
        cardTotal() {
            return this.couponList.length + this.exchangeCardList.length;
        },
        payMethod() {
            if (this.orderInfo.amount == 0) {
                //只有使用了优惠券完全抵扣 才是0元支付
                return 'zeroPay';
            }
            if (this.payWay == 'wx') return 'wxPay';
            if (this.payWay == 'accountPay') return 'accountPay'
            return;

            if (this.orderInfo.amount && !this.payWay) return void (0);
            let payMethod = '';
            payMethod += this.useVip ? 'vipPay,' : '';
            payMethod += this.payWay == 'zfb' ? 'aliWapPay' : '';
            payMethod += this.payWay == 'wx' ? (this.platform == 'wx' ? 'wxjspay' : 'wxwappay') : '';
            return payMethod;
        },
    },
    methods: {
        subtr,
        //获取订单信息
        getOrderInfo() {
            return new Promise((resolve, reject) => {
                getOrder(this.orderId).then((res) => {
                    let order = res;
                    this.orderInfo = order;
                    console.log('订单信息 ->', order)
                    this.$nextTick(() => {
                        this.$refs.countDown.start();
                        this.adjustFooterStyle();
                    })
                    resolve(res);
                }).catch((error) => {
                    reject(error);
                });
            })
        },
        //获取优惠券
        getOrderCoupon() {
            queryCouponByOrder({ orderId: this.orderId }).then((res) => {
                console.log(res);
                this.orderAvailableCoupon = filter(res, (item) => item.unUseReasonList == null || item.unUseReasonList.length < 1);
            })
        },
        toPickCoupon() {
            this.$goPage("order-coupon", {
                orderId: this.orderId,
                qty: this.orderInfo.quantity
            })
        },
        getCouponInfo() {
            return new Promise((resolve, reject) => {
                this.$service.getMemberOrderCouponList({ tradeno: this.orderId }).then((res) => {
                    this.couponList = this.filterCouponList(res.couponlist, item => item.type === 'A');
                    this.unUsedCouponList = this.filterCouponList(res.unusedcouponlist, item => item.type === 'A');
                    this.exchangeCardList = this.filterCouponList(res.couponlist, item => item.type === 'D');
                    this.unUsedExchangeCardList = this.filterCouponList(res.unusedcouponlist, item => item.type === 'D');
                    resolve(res);
                }).catch((error) => {
                    reject(error);
                });
            })
        },
        filterCouponList(list, callback) {
            if (!list || list.length < 1) return [];
            return list.filter(callback);
        },
        //获取订单剩余支付时间
        getOrderRemainingPayTime() {
            this.$service.getOrderRemaining({ tradeno: this.orderId }).then((res) => {
                this.payRemainingTime = res.remaining * 1000;
                setTimeout(() => {
                    this.$refs.countDown.start();
                })
            });
        },
        payTimeout() {
            this.$toast.allowMultiple();
            let timeout = this.$toast('订单支付已超时');
            setTimeout(() => {
                this.$goPage('movie-order', null, true);
            }, 0)
        },
        toggleCoupon(index) {
            this.$refs.checkboxes[index].toggle();
        },
        doSelectCoupon() {
            this.showCoupon = false;
            if (this.orderInfo.quantity < this.selectedCouponListTemp.length) {
                this.$toast('使用卡券的数量不能超过座位数');
                this.selectedCouponListTemp = Object.assign([], this.selectedCouponList);
                return false;
            }
            this.modifySelectedCoupon().then(() => {
                this.selectedCouponList = Object.assign([], this.selectedCouponListTemp);
                //后端更新优惠券使用状态 前端刷新订单
                this.getOrderInfo().then(() => {
                    this.checkAmountToResolvePayWay();
                });
            }).catch((error) => {
                console.log(error)
                this.selectedCouponListTemp = Object.assign([], this.selectedCouponList);
            });
        },
        //点击优惠券遮罩层关闭 则取消选择
        cancelSelectCoupon() {
            this.selectedCouponListTemp = Object.assign([], this.selectedCouponList);
        },
        //更改选中优惠券信息
        modifySelectedCoupon() {
            return new Promise((resolve, reject) => {
                let params = {
                    tradeno: this.orderId,
                    cardnos: this.selectedCouponTempNos,
                }
                this.$service.modifyCouponByOrder(params).then((res) => {
                    resolve();
                }).catch((err) => {
                    reject(err);
                })
            })
        },
        //检查订单金额决定支付方式 0元则无需wx zfb支付
        checkAmountToResolvePayWay() {
            if (this.orderInfo) {
                if (this.orderInfo.amount) {
                    this.payWay ? '' : this.payWay = 'wx';
                } else {
                    this.payWay = null;
                }
            }
        },
        validRead() {
            if (!this.readFlag) {
                this.$toast('请确认已读购票须知');

                this.$refs.fillbox.scrollTop = this.$refs.fillbox.scrollHeight;

                this.readBlink = true;
                setTimeout(() => {
                    this.readBlink = false;
                }, 600)
                return false;
            }

            return true;
        },
        beforePay(confirm) {
            if (!this.validRead()) return false;
            if (confirm !== true) {
                this.showTips = true;
                return false;
            }
            this.$toast.allowMultiple();
            if (this.payMethod) {
                let loading = this.$toast.loading(this.$config.loadingMsg);
                this.doPayment().then((res) => {
                    loading.clear();
                    if (this.payMethod == 'zeroPay') {
                        //这里属于0元支付
                        this.$goPage('movie-order', {}, true);
                    }
                    if (this.platform == 'wx') {
                        if (this.payWay == 'accountPay' && !res.wx_pay) {
                            //微信内置浏览器中支付宝支付跳转
                            // let href = this.aliPayWebView + this.orderId;
                            // window.location.href = href;
                            this.$toast('支付成功')
                            this.$goPage('movie-order', {}, true);
                        } else if (res.wx_pay) {
                            //微信内置浏览器中微信支付
                            //TODO 支付成功
                            wxPaymentV2(res.pay_token, () => {
                                this.$goPage('movie-order', {}, true);
                            });
                        }
                        // if (this.payWay == 'accountPay') {
                        //     //微信内置浏览器中支付宝支付跳转
                        //     // let href = this.aliPayWebView + this.orderId;
                        //     // window.location.href = href;
                        //     this.$toast('支付成功')
                        //     this.$goPage('movie-order', {}, true);
                        // } else if (this.payWay == 'wx') {
                        //     //微信内置浏览器中微信支付
                        //     //TODO 支付成功
                        //     wxPaymentV2(res, () => {
                        //         this.$goPage('movie-order', {}, true);
                        //     });
                        // }
                    } else {
                        if (this.payWay == 'accountPay') {
                            this.$toast('支付成功')
                            this.$goPage('movie-order', {}, true);
                            //其他浏览器中支付使用表单提交至支付宝
                            // let formData = Object.assign({}, res);
                            // this.formAction = res.payurl;
                            // this.formData = formData;
                            // setTimeout(
                            //     "document.aliPayForm.submit()",
                            //     800
                            // );
                        } else if (this.payWay == 'wx') {
                            //其他浏览器中使用微信支付 跳转
                            setTimeout(() => {
                                this.showPayStatus = true;
                                document.location.href = res.payurl;
                            }, 0);
                        }
                    }

                }).catch((error) => {
                    if (error && error.errorCode == '9999') {
                        this.$dialog.confirm({
                            title: '系统提示',
                            message: '账户余额不足，是否前去充值?',
                            confirmButtonText: '去充值',
                        }).then(() => {
                            this.$goPage('user-balance')
                        })
                    }
                    loading.clear();
                });
            } else {
                this.$toast('请选择支付方式');
                return false;
            }
        },
        doPayment() {
            return new Promise((resolve, reject) => {
                let params = {
                    payMethod: this.payMethod,
                    orderId: this.orderId,
                    supportChangeSeat: this.checkedSwitch ? 'Y' : 'N',
                    // openid: this.openId,
                }
                doOrderPay(params).then((res) => {
                    resolve(res);
                }).catch((error) => {
                    reject(error);
                })
            })
        },
        confirmPay() {
            this.showPayStatus = false;
            this.$goPage('order-pay-done', {
                orderId: this.orderId,
            }, true);
        },
        onFocusMobile() {
            this.focusMobile = true;
        },
        onBlurMobile() {
            this.focusMobile = false;
        },
        changeRead() {
            this.$goPage('order-pay', { orderId: this.orderId, read: true }, true);
        },
        adjustFooterStyle() {
            const footerWrap = document.querySelector('.footer-wrap');
            this.footerHeight = { height: footerWrap.clientHeight + 'px' };
            console.log('adjustFooterStyle -> ', footerWrap.clientHeight)
        },
        goBalance() {
            this.$goPage('user-balance')
        },
        initPage() {
            // this.getOrderRemainingPayTime();
            this.$store.dispatch('getMemberInfo')
            this.getOrderInfo();
            this.getOrderCoupon();
            return;
            Promise.all([this.getOrderInfo()]).then((result) => {
                let cards = this.orderInfo.items;
                if (this.orderInfo.vipuseamount) {
                    this.changeVip(true);
                } else {
                    if (cards && cards.length > 0) {
                        cards.forEach((item) => {
                            if (item.itemtype != 'N') {
                                let tempResult = this.couponList.filter((coupon) => coupon.cardno == item.cardno)
                                    .concat(this.exchangeCardList.filter((card) => card.cardno == item.cardno));
                                if (tempResult.length > 0) {
                                    this.selectedCouponListTemp.push(item.cardno);
                                }

                            }
                        });
                        this.selectedCouponList = Object.assign([], this.selectedCouponListTemp);
                        this.checkAmountToResolvePayWay();
                    }
                }

            })
        },
    },
    watch: {
        'selectedCouponList': {
            immediate: true,
            handler(val) {
                if (val.length > 0) {
                    this.useVip = false;
                }
            }
        },
        'useVip': {
            handler(val) {
                if (val) {
                    this.checkAmountToResolvePayWay();
                }
            }
        },
        'payWay': {
            handler(val) {
                if (val) {
                    this.checkAmountToResolvePayWay();
                }
            }
        },
        focusMobile: {
            handler(val) {
                if (val) {
                    this.$nextTick(() => {
                        this.$refs.receiveMobile.focus();
                    })
                }
            }
        },
        checkedSwitch: {
            handler(val) {
                const { forceAcceptChgSeat } = this.orderInfo
                if (!val && forceAcceptChgSeat === 'Y') {
                    this.checkedSwitch = true;
                }
            }
        }
    },
    created() {
        this.initPage();
    },
    mounted() {
        this.adjustFooterStyle();
        const ob = new ResizeObserver((entries) => {
            for (let entry of entries) {
                console.log(entry);
                this.footerHeight = { height: entry.borderBoxSize[0].blockSize + 'px' }
            }
        });
        ob.observe(document.querySelector(".footer-wrap"));
    },
    // beforeRouteLeave(to, from, next) {
    //     console.log(123)
    //     this.$dialog.confirm({
    //         title: '系统提示',
    //         message: '确认返回吗?'
    //     }).then(() => {
    //         next();
    //     });
    //     return false;
    // },
    filters: {
        getTicketLength(val) {
            return val ? val.split(',').length : 0;
        }
    },
    components: {
        AliPayForm,
        OrderCouponPopup
    },
    metaInfo: {
        meta: [
            { name: 'referrer', content: 'always' }
        ]
    },
}
</script>

<style lang="scss" scoped>
.body-wrap {
	position: relative;
	.fill-box {
		padding: 0 12px;
		> div {
			margin: 10px 0;
			border-radius: 8px;
			background-color: #fff;
			&.order_content-wrap {
				padding: 15px;
				.order_content {
					> div:first-child {
						margin-right: 15px;
					}
					.order_poster {
						border-radius: 4px;
						overflow: hidden;
						width: 90px;
						height: 125px;
					}
					.order_name {
						font-size: 16px;
						font-weight: 700;
						// font-family: "pf medium";
					}
				}
			}
			.cell-icon {
				margin-right: 15px;
			}
			&.order_instructions-wrap {
				padding: 15px;
				/deep/ p {
					margin: 5px 0;
					> span {
						// color: #fa3534;
						// font-weight: 700;
					}
				}
			}
		}
	}
	.card-wrap {
		margin: 10px 12px;
		background-color: white;
		border-radius: 4px;
		overflow: hidden;
	}
}

.available-coupon {
	border-radius: 4px;
	background: linear-gradient(to right, #ff9565, #fb2c4c);
	color: #eee;
	padding: 3px 6px;
	font-size: 0.9em;
}

.widen {
	min-width: 58%;
}
.widen70 {
	min-width: 70%;
}

.utils-wrap {
	/deep/ .van-popup__close-icon {
		top: 8px;
		right: 15px;
		font-size: 18px;
		color: $main-color;
	}
	/deep/ .van-cell__value {
		padding-right: 18px;
	}
	/deep/ .checkbox_icon {
		position: absolute;
		top: 0;
		right: 0;
		width: 32px;
		height: 32px;
		border: 1px solid red;
		background-color: #e42f46;
		border-top-right-radius: 8px;
		border-bottom-left-radius: 40px;
	}
}

.footer-wrap {
	position: fixed;
	width: 100%;
	bottom: 0;
	left: 0;
	padding: 15px 20px;
	background-color: #fff;
	z-index: 999999;
	.order_amount {
		font-size: 16px;
		> span:nth-child(2) {
			font-size: 18px;
		}
	}

	.order-detail_text {
		color: $tips-color;
		text-decoration: underline;
		font-size: 12px;
	}
}

.order-food-wrap {
	padding: 6px 0;
	/deep/ .order-food {
		.van-collapse-item__title,
		.van-collapse-item__content {
			padding: 4px 0;
		}

		.order-food--value {
			padding: 2px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}
	}
}

.tips-content {
	padding: 16px;
	background-color: #fff;

	.tips-movie {
		margin: 20px 0;
	}

	.tips-playtime {
		font-size: 14px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.tips-chupiaotime,
	.tips-remark {
		font-size: 12px;
		color: $tips-color;
		display: flex;
		flex-direction: row;
		justify-content: center;
		margin: 20px 0 10px;
	}

	.tips-remark {
		display: block;
	}
}

.detail-wrap {
	height: 100%;
	.detail-body {
		padding-bottom: 20px;
		.detail-title {
			font-size: 15px;
			font-weight: 700;
			margin-bottom: 10px;
		}
		.detail-item {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			padding: 4px 0;
		}
	}
}

@keyframes blink {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.text-blink {
	animation: blink 0.3s infinite linear;
}
</style>
